<template>
  <div v-if="iconSvg" v-html="iconSvg" class="icon-svg-body"></div>
</template>

<script>
import { computed } from "vue";
import * as allIcons from "ionicons/icons";

export default {
  props: {
    icon: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const iconSvg = computed(() =>
      allIcons[props.icon]
        ? allIcons[props.icon]
            .split(",")[1]
            .replace(/<title>([\S\s]*?)<\/title>/, "")
        : ""
    );

    return {
      iconSvg,
    };
  },
};
</script>

<style lang="less" scoped>
.icon-svg-body {
  display: inline-block;
  width: 1em;
  height: 1em;
  contain: strict;
  fill: currentcolor;
  box-sizing: content-box !important;
}
</style>
