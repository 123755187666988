export const m = {
  // login
  pleaseInputNickName: 'ニックネームを入力してください',
  pleaseInputNickNameTip: '12文字以内のニックネームを入力してください',
  pleaseInputCode: '認証コードを入力してください',
  certification: '認証',
  useGuideClick: '利用案内はこちら',
  noEventTip: '参加できるイベントがないです',
  certificationFailed: '認証失敗しました',
  subtitleTip: '字幕言語を選択してください',
  // device test
  deviceTest: '設備テスト',
  permissionPermitTip: 'カメラ・マイクの権限を許可してください。',
  deviceToConnection: '設備へ接続中',
  deviceTestStartTip: '設備及びネットの接続が成功しました。テストを開始してください。',
  deviceTestFailedTip: '設備及びネットの接続が失敗しました。もう一度、設備を確認してください。',
  deviceTestStart: 'テスト開始',
  skip: 'スキップ',
  cameraSelection: 'カメラ選択',
  startingCamera: 'カメラ起動中',
  testCameraTip: 'カメラは起動していますか？',
  speakerSelection: 'スピーカー選択',
  devicePlayMusicTip: '再生ボタンで音楽が流れます。',
  devicePlayMusicTip2: '音量を調整してください。',
  testMicTip: '何か話しましょう',
  micSelection: 'マイク選択',
  testMicTip2: '声を出した時にピンクのバーは動いていますか？',
  netStatus: 'ネット状況',
  inTest: 'テスト中',
  netTestTip: 'テスト中です。約1〜2分かかる場合があります。しばらく経っても自動で画面が切り替わらない場合はネットワーク状態が不安定な可能性があります。',
  netTestTip2: 'ネットワーク環境をご確認の上、ブラウザのリロード、または再起動をお試しください。',
  netResTip: '現在のネットワークの状態：良い',
  netResTip2: 'このままネットワーク環境を安定させてください。',
  netResTip3: '現在のネットワークの状態：あまり良くない',
  netResTip4: '会話が遅延が生じる場合がありますので、ネットワーク環境の良い場所へ移動してください。',
  netResTip5: '現在のネットワークの状態：エラー',
  netResTip6: 'ネットワークが見つかりません。移動して頂くか推奨環境の確認をお願いします。',
  netResTip7: '現在のネットワークの状態：非常に悪い',
  netResTip8: '会話が出来ない可能性がありますので、必ずネットワーク環境の良い場所へ移動してください。',
  toTestResult: 'テスト結果へ',
  testResult: 'テスト結果',
  camera: 'カメラ',
  speaker: 'スピーカー',
  microphone: 'マイク',
  checkOk: '正常',
  checkFailed: '異常',
  unknown: '未知',
  good: '良い',
  notGood: 'よくない',
  error: 'エラー',
  bad: '悪い',
  redo: 'やり直す',
  completed: '完了',
  devicePageErrorTip: '端末またはブラウザはご利用いただけません。',
  devicePageErrorTip2: '推奨環境をご確認ください。',
  devicePageErrorTip3: '推奨環境',
  devicePageErrorTip4: 'Windows10 最新バージョンのGoogle Chrome、Microsoft Edge',
  devicePageErrorTip5: '※Internet Explorer未対応',
  devicePageErrorTip6: 'macOS11.0以上 (最新バージョンのGoogle Chrome、Safari)',
  devicePageErrorTip7: 'スマートフォン・タブレット',
  devicePageErrorTip8: 'Android 8 以上(最新バージョンのGoogle Chrome)',
  devicePageErrorTip9: 'iOS 12.0以上(最新バージョンのSafari)',
  devicePageErrorTip10: 'モバイル端末',
  devicePageErrorTip11: 'iPhone：iPhone7以降（7含む）',
  devicePageErrorTip12: 'Android：概ね直近3年以内に発売された端末',
  devicePageErrorTip13: '備考：Huawei端末非対応（使用上制限されている機能があるため）',
  devicePageRemote: "配信画像",
  devicePageLocal: "元画像",
  devicePageTrtcTestTip: '元画像と配信画像の両方でカメラの映像を見ることができますか？',
  devicePageTrtcTestTip2: '話すとき、自分の声が聞こえますか？',
  // Entrance
  shootingGuideTip: '〈撮影ガイド〉',
  shootingGuideTip2: '携帯を持ち上げてね',
  shootingGuideTip3: '手を真っすぐ伸ばしてね',
  shootingGuideTip4: '白枠に沿ってタレントとポーズを調整してね',
  shootingGuideTip5: '※注意点',
  shootingGuideTip6: 'スマートフォンを使ってください',
  shootingGuideTip7: '机の上に置かないでください',
  shootingGuideTip8: 'こんな写真が撮れるよ！',
  shootingGuideTip9: '〈注意事項〉',
  shootingGuideTip10: '注意事項を読み、',
  shootingGuideTip11: '{eventExpress}開始10分前になると「同意して次へ」ボタンが押せます。',
  shootingGuideTip12: '{eventExpress}開始まで必ず待合室でお待ちください。ネットワーク不安定を招く原因となりますので、画面は開いたままで、アプリ間やブラウザ間の切替をお控えください。',
  shootingGuideTip13: 'やむを得ない理由で離れ、お戻りになられた際に画面が表示されないことが発生しましたら、ページをリフレッシュしてください。',
  shootingGuideTip14: '〈禁止事項〉',
  shootingGuideTip15: '誹謗中傷、罵声、その他嫌がらせと取られる行為、デバイスの録画機能、スクリーンショットのご使用はおやめください。',
  shootingGuideTip16: '品質向上のため一部記録させていただきます。ご理解のほどよろしくお願い致します。',
  // プライバシーポリシー
  privacyPolicyTitle: 'プライバシーポリシー',
  agreementNext: '同意して次へ',
  // PositionSelect
  leftRightPositionSelection: '左右位置選択',
  left: '左',
  right: '右',
  leftRightDemoTip: '※デモのため、右側しか選べません',
  positionSelectTip: 'まず左右位置を決めてね。',
  // ChatRoom
  chatRoomTip: '開始まで暫くお待ち下さい',
  chatRoomTip2: '表示メッセージが上限に達しました',
  chatRoomTip3: 'これ以上のメッセージはありません',
  chatRoomErrorTip: '審査ルールにより発言が拒否されました',
  sendComment: 'コメントする',
  chatRoomName: '{nickName}のチャットルーム',
  // meetIndex
  meetIndexErrorTip: '必ずカメラとマイクのアクセスを許可してください',
  meetIndexErrorTip2: 'ネットワークまたはデバイスのエラーが発生しました。ページを再読み込みしてください。',
  meetIndexErrorTip3: 'カメラまたはマイクのエラーが発生しました。ページを再読み込みしてください。',
  meetIndexErrorTip4: '動画または音声にエラーが発生しました。',
  meetIndexErrorTip5: 'カメラまたはマイクのエラーが発生しました。もう一度許可してください。',
  meetIndexErrorTip6: '<div class="tl">カメラとマイクがブロックされています<br />ブラウザの「リフレッシュ」のアイコン<img src="/img/rotate.png" height="20" />をクリックして、ページを更新してください。</div>',
  meetIndexErrorTip7: '<div class="tl">カメラとマイクがブロックされています<br />カメラとマイクへのアクセスを許可する必要があります。ブラウザのアドレスバーのブロックされた「ロック」のアイコン<img src="/img/lock.png" height="20" />をクリックして、「権限」をクリックして、カメラとマイクをオープンしてください。</div>',
  meetIndexErrorTip8: 'ツーショットの有効期限が切れました！',
  meetIndexErrorTip9: 'マイクの初期化が失敗しました',
  // 无法进入下一阶段，请刷新页面后再次尝试。
  meetIndexErrorTip10: '次のステージに進めません。ページを再読み込みしてください。',
  // Meeting
  meetingTip: 'ネットワークが不安定です。',
  meetingTip2: 'お確かめください。',
  meetingTip3: 'この後はツーショット撮影ルームへ',
  // ShootingRoom
  shootingRoomTip: 'ツーショット撮影ルーム',
  shootingRoomTip2: '撮影を準備しましょう。',
  shootingRoomTip3: 'カウントダウン終了後、撮影が自動的に行います。',
  shootingRoomTip4: '写真は二枚を連続撮影します。',
  // WaittingRoom
  waittingRoomStatus: '待機中',
  waittingRoomCameraTitle: 'カメラの映像',
  waittingRoomMicTest: 'マイクテスト',
  waittingRoomVoiceTest: '音量テスト',
  waittingRoomTip: '*お願い',
  waittingRoomTip2: 'デバイスの録画機能、スクリーンショットの使用はおやめください。',
  waittingRoomTip3: '上記のマイク・スピーカーアイコンで最終確認をしてください。',
  waittingRoomTip4: '音量テストボタンをタップして、音量を調整してください',
  waittingRoomTip5: '映像や文字は反転せず、正しく相手に映ります。',
  waittingRoomTip6: 'マイクテストは声を出すと反応します',
  // Cert
  certEventEnd: '{eventExpress}終了',
  certEventEndTip: '{eventExpress}お疲れさまでした！',
  certErrorTip: 'サイン失敗しました！',
  certErrorTip2: '下記フォームにお問い合わせください',
  certErrorTip3: 'サインの有効期限が切れました！',
  certErrorTip4: 'サイン失敗しました',
  certTip: '画像を保存してSNS等でみんなに自慢しよう！',
  certTip2: '{eventExpress}に関するお問い合わせはこちら',
  certTip3: '{eventExpress}を評価しましょう',
  certMarking: '作成中',
  certificate: '参加証',
  joinTime: '参加時間',
  liverEvent: '{nickName}の{eventExpress}',
  mrMiss: '{nickName}さん',
  // EditPhoto
  editPhotoTitle: 'ツーショット編集ルーム',
  editPhotoTip: '対象を選び、編集を行ってください。',
  editPhotoTip2: '下の画像を確認してください',
  standardStyle: '標準スタイル',
  particularAdjustment: '細かい調整',
  positionAdjustment: '位置調整',
  dark: '暗い',
  cold: '冷たい',
  warm: '暖かい',
  bright: '明るい',
  brightness: '明るさ',
  contrast: 'コントラスト',
  saturation: '鮮やかさ',
  editComplete: '編集完了',
  editPhotoErrorTip: '写真の切り抜きに失敗しました',
  editPhotoErrorTip2: '運営事務局でツーショット写真を合成できるか調査しますので',
  editPhotoErrorTip3: '下記フォームにお問い合わせください',
  // SavePhoto
  savePhotoTip: 'ツーショットが発行されたよ！スマホの場合は画像を長押すると端末に保存することができます。',
  savePhotoTip2: 'このページは、{expirationTime}まで有効。',
  savePhotoTip3: '保存できない場合は、下のボタンをクリックしてください。',
  savePhotoTip4: '画像を開く',
  savePhotoTip5: '保存中',
  savePhotoErrorTip: 'お問い合わせはこちら',
  savePhotoErrorTip2: '視聴に関する不具合はこちら',
  savePhotoDemoExp: '2125年12月30日',
  // SelectPhoto
  selectPhotoTitle: 'ツーショット編集ルーム',
  selectPhotoTip: 'お好きな一枚をお選びください。',
  selectPhotoErrorTip: 'ツーショット撮影、写真アップロードに失敗しました',
  selectPhotoErrorTip2: '運営事務局でツーショット写真を合成できるか調査しますので',
  selectPhotoErrorTip3: '下記フォームにお問い合わせください',
  // Inquiry
  inquiryEventEnd: '{eventExpress}終了',
  inquiryEventEndTip: 'お疲れさまでした！',
  // Evaluate
  evaluateTip: '音声と動画の品質はいかがでしょうか？',
  evaluateGood: 'とても良い',
  evaluateBad: 'とても悪い',
  // LiveEntrance
  liveEntranceTitle: 'Live 配信',
  liveEntranceTip: '無断での配信内容の録画・録音、不特定多数が閲覧できる場所（SNSなど含め）への投稿・転載は禁止いたします。',
  liveEntranceTip2: 'このような行為が発覚し主催者が必要と判断した場合には、警察への通報等、捜査機関の協力を仰ぐことがあります。',
  liveEntranceTip3: 'マナーを守ってお楽しみください。',
  liveEntranceBtn: '同意して、視聴する',
  // LiveWait
  liveWait: 'Live準備中',
  liveWaitTip: '長い間再生されていない場合、ページを再読み込みしてください。',
  // LiveStreaming
  liveStreamingTitle: 'ライブ配信「{title} 」',
  liveStreamingStartTime: 'ライブ配信開始日',
  liveStreamingWatchNewComment: '新しいコメントをみる',
  liveStreamingGift: '{nickName}さんは{giftName}を贈ったよ',
  liveStreamingGift2: '{giftName}を贈ったよ',
  liveStreamingWelcome: 'WeShoWライブ配信へようこそ。',
  liveStreamingTip: 'ボタンをクリックして、ライブを始めましょう！',
  liveStreamingWording1: 'ライブ配信読み込み中、しばらくお待ちください。',
  liveStreamingWording2: 'ライブ配信読み込み中、しばらくお待ちください。',
  liveStreamingWording3: 'ビデオデコードエラー。',
  liveStreamingWording4: '現在のシステム環境では、このビデオフォーマットの再生はサポートされていません。',
  liveStreamingWording5: '現在のシステム環境では、このビデオフォーマットの再生はサポートされていません。',
  liveStreamingWording10: 'fileプロトコルでプレーヤーを使わないでください。ビデオが再生できなくなる恐れがあります。',
  liveStreamingWording11: '使用パラメータが間違っています。プレーヤーの呼び出しコードを確認してください。',
  liveStreamingWording12: 'ビデオの再生アドレスを入力してください。',
  liveStreamingWording13: 'Live配信は終了しましたので、後で来てください。',
  liveStreamingWording1001: 'ネットワークエラー。ネットワーク設定またはリンクが正しいかどうかを確認してください。',
  liveStreamingWording1002: 'ビデオの取得に失敗しました。リンクが有効かどうかを確認してください。',
  liveStreamingWording2001: 'ライブ配信読み込み中、しばらくお待ちください。',
  liveStreamingWording2002: 'ライブ配信読み込み中、しばらくお待ちください。',
  liveStreamingWording2003: 'ライブ配信がされていません。',
  liveStreamingWording2032: 'ビデオの取得に失敗しました。リンクが有効かどうかを確認してください。',
  liveStreamingWording2048: 'ビデオファイルを読み込めません。クロスドメインアクセスが拒否されます。',
  liveStreamingErrorTip: '別の場所でログインしているため、強制的にログアウトされました。',
  liveStreamingErrorTip2: '審査ルールにより発言が拒否されました',
  // LiveEnd
  liveEnd: 'LIVEは終了しました',
  // CheckOrder
  checkOrderTip: 'メールを入力してください',
  checkOrderTip2: '注文番号を入力してください',
  checkOrderTip3: '※注文番号は、購入完了時メールに記載されております。',
  checkOrderErrorTip: '正しいメールを入力してください',
  checkOrderErrorTip2: '注文番号をセットしてください',
  // CheckOrderDetail
  checkOrderDetailErrorTip: 'エラー',
  checkOrderDetailErrorTip2: `購入内容が確認できません。
今一度、メールアドレス・注文番号をご確認ください。`,
  checkOrderDetailTip: '購入内容',
  // PayEnd
  payEndErrorTip: 'エラー',
  payEndErrorTip2: '支払結果を取れないため、',
  payEndErrorTip3: '後日支払結果を登録したEmail：{email}に送信します。',
  payEndErrorTip4: '送信メールアドレス、ごみ箱に入れる可能性があります。',
  payEndTip: '購入内容',
  payEndTip2: '上記内容をEmail： {email}にも送信いたします。',
  // PayEvent
  remainingCount: '残り枚数',
  eventIntroduction: 'イベント紹介',
  liverIntroduction: 'ライバー紹介',
  contactUs: '問い合わせ',
  toWebContactUs: 'Webフォームでお問い合わせへ',
  payEventContactUsTip: 'お問合せの受付は、本イベントの申込・参加方法などのみの受付に限らせていただきます。',
  payEventContactUsTip2: '出演者個人への質問やイベント内容詳細に関しましては、ご返信ができませんので、あらかじめご了承くださいますようお願いいたします。',
  payEventContactUsTip3: 'お問合せ対応時間：平日10:00～18:00',
  inputScreen: '入力画面',
  verificationScreen: '確認画面',
  eventTitle: 'イベント名',
  personallyIdentifiableInformation: '個人情報',
  purchasePrice: '購入価格',
  payEventTip: 'まだ支払い確認できないオーダーがあります。オーダーをキャンセルしますか？',
  payEventTip2: 'メールアドレスを入力',
  payEventTip3: '購入枚数を入力してください',
  payEventTip4: 'メールアドレスを再入力',
  payEventTip5: '※確認のため再入力をお願いします',
  reconfirmation: '再確認',
  payEventErrorTip: '名前を入力してください',
  payEventErrorTip2: '正しいメールを入力してください',
  payEventErrorTip3: '在庫なし',
  payEventErrorTip4: '購入制限を超えております',
  payEventErrorTip5: '2回入力したメールアドレスが一致しません',
  payEventErrorTip6: '確認用メールアドレスを入力してください',
  payEventErrorTip7: '残り枚数不足、再入力してください。',
  payMaxOnce: '一回購入最大枚数',
  // ua check error
  uaCheckErrorTitle: '現在アクセスしている端末またはブラウザではご利用いただけません。',
  uaCheckErrorTitle2: `大変申し訳ございません。
現在お客様がアクセスされている端末またはブラウザではお申込みいただけません。`,
  uaCheckErrorTip: '推奨環境をご確認のうえ、下記URLを推奨環境にてコピーペーストして頂き、再度アクセスしてください。',
  uaCheckErrorTip2: 'お申し込みを希望される部の販売URLをコピーしていただき、以下の推奨環境で再度アクセスをお願いいたします。',
  // common
  return: '戻る',
  no: 'いいえ',
  yes: 'はい',
  fan: 'ファン',
  errorTip: 'エラーが発生しました',
  remaining: 'あと',
  remaining2: '残り',
  user: 'ユーザー',
  day: '日',
  hours: '時間',
  minutes: '分',
  seconds: '秒',
  // 简写的时间
  d: '日',
  h: '時間',
  m: '分',
  s: '秒',
  YYYYMMDD: 'YYYY年MM月DD日',
  aboutTime: '約',
  more: 'もっと見る',
  send: '送信',
  reload: '再読み込み',
  recovery: '回復',
  permission: '許可',
  redo: 'やり直す',
  sendCompleted: '送信しました',
  sendError: '送信失敗しました',
  toNext: '次へ',
  toInquiry: 'お問い合わせへ',
  save: '保存',
  check: 'チェック',
  toOrderInquiry: '購入内容確認へ',
  photoProcessing: '写真処理中',
  eventInfo: 'イベント情報',
  title: 'タイトル',
  liver: '出演者',
  schedule: 'スケジュール',
  start: '開始',
  end: '終了',
  notice: 'お知らせ',
  reservationNumber: '予約枚数',
  ticketsNumber: '{count}枚',
  tickets: '枚',
  authCode: '認証コード',
  cautions: '注意事項',
  distributor: '販売元',
  isCancel: 'キャンセルしました',
  ticketDetail: 'チケット詳細',
  eventDetail: 'イベント詳細',
  inConfirmation: '確認中',
  EBCompanyName: '株式会社イー・ビジネス',
  joinUrl: '参加URL',
  useGuide: 'ご利用案内',
  buy: '購入',
  cantBuy: '販売時間外',
  isNoStock: '売り切れ',
  name: '名前',
  userAgree: `<a
  href="https://guide.weshow.co.jp/terms_user"
  target="_blank"
  class="cInherit unl"
  >ユーザー利用規約</a
>に同意します`,
  policyAgree: `<a
href="https://guide.weshow.co.jp/privacy_policy"
target="_blank"
class="cInherit unl"
>プライバシーポリシー</a
>に同意します`,
  termsUser: `ユーザー利用規約`,
  privacyPolicy: `プライバシーポリシー`,
  termsLaw: `特定商取引法に基づく表記`,
  loading: 'ローディング',
  // 暂停中
  liveIsPaused: '一時停止',
  // 只今、準備中です。<br />再開するまでお待ちください。
  liveIsPreparing: '只今、準備中です。<br />再開するまでお待ちください。',
  // 了解
  know: '了解',
  // 时间校准失败请重新刷新页面
  checkDelayError: '時間校准失敗しました。ページを再読み込みしてください。',
  // 时间校准失败请确认网络环境后再次尝试
  checkDelayError2: '時間校准失敗しました。ネットワーク環境をご確認の上、再度お試しください。'
};