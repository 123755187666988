import { createStore } from "vuex";
import { authApi } from "@/api";
import { isDuringDate } from '@/utils/utils';
let checkIsStopPromise = null;
export default createStore({
  state: {
    token: localStorage.getItem("token") || "",
    nicnName: "",
    loadingShow: false,
    eventDetail: null,
    jointPhoto: null,
    trtcData: {
      client: null,
      remoteStream: null,
      localStream: null,
      streamingInfo: null
    },
    eventExpress: "",
    eventId: "",
    serverDelay: 0,
    checkDelayOk: false,
    checkDelayPromise: null,
    checkDelayIsError: false,
    nowTime: new Date().getTime(),
    vol: 0,
    networkIsLow: false,
    isBan: false,
    winHeight: 0,
    winWidth: 0,
    trySEFlag: false,
    countStartFlag: false,
    liveIsPaused: false,
    appCheckDevicePromise: null
  },
  getters: {
    token: state => state.token,
    loadingShow: state => state.loadingShow,
    eventDetail: state => state.eventDetail,
    jointPhoto: state => state.jointPhoto,
    trtcData: state => state.trtcData,
    eventExpress: state => state.eventExpress,
    eventId: state => state.eventId,
    serverDelay: state => state.serverDelay,
    checkDelayOk: state => state.checkDelayOk,
    checkDelayPromise: state => state.checkDelayPromise,
    checkDelayIsError: state => state.checkDelayIsError,
    nowTime: state => state.nowTime,
    vol: state => state.vol,
    networkIsLow: state => state.networkIsLow,
    isBan: state => state.isBan,
    winHeight: state => state.winHeight,
    winWidth: state => state.winWidth,
    countStartFlag: state => state.countStartFlag,
    liveIsPaused: state => state.liveIsPaused,
    appCheckDevicePromise: state => state.appCheckDevicePromise
  },
  mutations: {
    setIsBan(state, data) {
      state.isBan = data;
    },

    setNetworkIsLow(state, data) {
      state.networkIsLow = data;
    },

    setVol(state, data) {
      state.vol = data;
    },

    setNowTime(state) {
      state.nowTime = new Date().getTime() + state.serverDelay;
    },

    setToken(state, data) {
      state.token = data;
    },

    setLoadingShow(state, data) {
      state.loadingShow = data;
    },

    setEventDetail(state, data) {
      state.eventDetail = data;
    },

    setJointPhoto(state, data) {
      state.jointPhoto = data;
    },

    setTrtcData(state, data) {
      state.trtcData[data.key] = data.value;
    },

    setTrctDataUsedFlag(state, data) {
      if (state.trtcData.streamingInfo) {
        state.trtcData.streamingInfo.usedFlag = data;
      }
    },

    setNickName(state, data) {
      state.nicnName = data;
    },

    setEventExpress(state, data) {
      state.eventExpress = data;
    },

    setEventId(state, data) {
      state.eventId = data;
    },

    setLoading(state, data) {
      state.loadingShow = data;
    },

    sendLog(state, data) {
      authApi.logSave({
        logContent: data
      });
    },

    setWinHeight(state, data) {
      state.winHeight = data;
    },

    setWinWidth(state, data) {
      state.winWidth = data;
    },

    setCountStartFlag(state, data) {
      state.countStartFlag = data;
    },

    setCheckDelayOk(state, data) {
      state.checkDelayOk = data;
    },

    setServerDelay(state, data) {
      state.serverDelay = data;
    },

    setCheckDelayIsError(state, data) {
      state.checkDelayIsError = data;
    },

    setCheckDelayPromise(state, data) {
      state.checkDelayPromise = data;
    },

    trySE(state) {
      if (!state.trySEFlag) {
        const demoLiverVideo = document.getElementById("demoLiverVideo");

        if (demoLiverVideo) {
          demoLiverVideo.play();
          demoLiverVideo.pause();
        }
      }

      if (!state.trySEFlag && state.eventDetail.eventType === 2) {
        state.trySEFlag = true;
        const se1 = document.getElementById("shootSE1");
        const se2 = document.getElementById("shootSE2");
        se1.play();
        se2.play();
      }
    },

    checkLiveIsPaused(state, isStop) {
      state.liveIsPaused = isStop;
    },

    setAppCheckDevicePromise(state, data) {
      state.appCheckDevicePromise = data;
    }

  },
  actions: {
    setIsBan({
      commit
    }, data) {
      commit("setIsBan", data);
    },

    setNetworkIsLow({
      commit
    }, data) {
      commit("setNetworkIsLow", data);
    },

    setVol({
      commit
    }, data) {
      commit("setVol", data);
    },

    setNowTime({
      commit
    }) {
      commit("setNowTime");
    },

    setToken({
      commit
    }, data) {
      commit("setToken", data);
    },

    setLoadingShow({
      commit
    }, data) {
      commit("setLoadingShow", data);
    },

    setEventDetail({
      commit
    }, data) {
      commit("setEventDetail", data);
    },

    setJointPhoto({
      commit
    }, data) {
      commit("setJointPhoto", data);
    },

    setTrtcData({
      commit
    }, data) {
      commit("setTrtcData", data);
    },

    setTrctDataUsedFlag({
      commit
    }, data) {
      commit("setTrctDataUsedFlag", data);
    },

    setNickName({
      commit
    }, data) {
      commit("setNickName", data);
    },

    setEventExpress({
      commit
    }, data) {
      commit("setEventExpress", data);
    },

    setLoading({
      commit
    }, data) {
      commit("setLoading", data);
    },

    setEventId({
      commit
    }, data) {
      commit("setEventId", data);
    },

    setWinHeight({
      commit
    }, data) {
      commit("setWinHeight", data);
    },

    setWinWidth({
      commit
    }, data) {
      commit("setWinWidth", data);
    },

    setCountStartFlag({
      commit
    }, data) {
      commit("setCountStartFlag", data);
    },

    sendLog({
      commit
    }, data) {
      commit("sendLog", data);
    },

    checkDelay({
      commit,
      state
    }, disabledAlert = false) {
      // debugger
      const promise = new Promise(async (resolve, reject) => {
        const delayList = [];
        let delay = null;
        const maxCount = 5;
        const maxDelay = 2000;
        let reqCount = 0;
        let isTimeOut = false;
        const accountId = localStorage.getItem("accountId");
        setTimeout(() => {
          isTimeOut = true;
        }, 2000);

        while (delay === null && reqCount < maxCount && !isTimeOut) {
          const now = new Date();
          const startTime = now.getTime();
          await authApi.syncTime({
            accountId: accountId,
            now: now
          }).then(res => {
            const endTime = new Date().getTime();
            const serverTime = new Date(res.data.now).getTime();
            const oneWayDelay = (endTime - startTime) / 2;
            const delayTime = serverTime - (startTime + oneWayDelay);
            console.log(delayTime);
            console.log(endTime - startTime, serverTime, endTime);
            delayList.push(delayTime);

            if (oneWayDelay < 100) {
              delay = delayTime; // state.serverDelay = Math.round(delayTime)
            }
          }).catch(err => {
            console.error(err);
          });
          reqCount++;
        }

        if (delay !== null) {
          commit("setServerDelay", Math.round(delay));
          commit("setCheckDelayOk", true);
          resolve();
        } else {
          // 取delayListAll中最小的值
          const minDelay = Math.min(...delayList);

          if (minDelay <= maxDelay) {
            commit("setServerDelay", Math.round(minDelay));
            commit("setCheckDelayOk", true);
            resolve();
          } else {
            commit("setCheckDelayOk", false);

            if (!disabledAlert) {
              commit("setCheckDelayIsError", true);
            }

            reject("delay is too long");
          }
        }

        console.log("checkDelay end", state.serverDelay);
      });
      commit("setCheckDelayPromise", promise);
    },

    trySE({
      commit
    }) {
      commit("trySE");
    },

    checkLiveIsPaused({
      commit,
      state
    }, reflush = false) {
      const promise = new Promise((resolve, reject) => {
        if (!reflush) {
          const streamingInfo = state.trtcData.streamingInfo;

          if (streamingInfo && streamingInfo.startTime) {
            // 如果是在开始时间和结束时间之间，就不用检查了,liveIsPaused肯定是false
            if (isDuringDate(streamingInfo.startTime, '9999-12-30')) {
              state.liveIsPaused = false;
              resolve(false);
              return;
            }
          }
        }

        authApi.checkIsStop(state.eventId).then(res => {
          const isStop = res.data.pauseFlag;
          state.liveIsPaused = isStop;
          console.log("checkLiveIsPaused", isStop);
          commit("checkLiveIsPaused", isStop);
          resolve(isStop);
        }).catch(err => {
          reject(err);
        });
      });
      checkIsStopPromise = promise;
      checkIsStopPromise.finally(() => {
        checkIsStopPromise = null;
      });
      return promise;
    },

    setLiveIsPaused({
      commit
    }, isStop) {
      if (checkIsStopPromise) {
        checkIsStopPromise.finally(() => {
          commit("checkLiveIsPaused", isStop);
        });
        return;
      }

      commit("checkLiveIsPaused", isStop);
    },

    setAppCheckDevicePromise({
      commit
    }, data) {
      commit("setAppCheckDevicePromise", data);
    }

  },
  modules: {}
});