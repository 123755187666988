let loadingCount = 0;
let loadingTimer = null;
let weshowThemeEventId = null;
import store from "@/store";
import { authApi } from "@/api";
import { useRoute, useRouter } from "vue-router";
import initThemeData from "@/utils/defaultTheme.json";

const startLoading = () => {
  store.dispatch('setLoading', true);
};

const endLoading = () => {
  store.dispatch('setLoading', false);
};

export const showLoading = () => {
  clearTimeout(loadingTimer);
  loadingTimer = setTimeout(() => {
    loadingCount = 1;
    hideLoading();
  }, 5000);

  if (loadingCount === 0) {
    startLoading();
  }

  loadingCount += 1;
};
export const hideLoading = () => {
  if (loadingCount <= 0) {
    return;
  }

  loadingCount -= 1;

  if (loadingCount === 0) {
    clearTimeout(loadingTimer);
    endLoading();
  }
};
export const nicnNameSubstring = nickName => {
  return nickName.substring(0, 2);
};
export const creatColorByNickName = NickName => {
  // nick name to code
  let id = 0;

  for (var i = 0; i < NickName.length; i++) {
    id += NickName.charCodeAt(i);
  }

  id = id % 100;
  const h = id * (360 / 100);
  const s = 80;
  const l = 30;
  return hslToHex(h, s, l);
};
export const hslToHex = (h, s, l) => {
  l /= 100;
  const a = s * Math.min(l, 1 - l) / 100;

  const f = n => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color).toString(16).padStart(2, "0"); // convert to Hex and prefix "0" if needed
  };

  return `${f(0)}${f(8)}${f(4)}`;
};
export const isDuringDate = (beginDateOri, endDateOri) => {
  //
  let curDate = new Date().getTime() + store.getters.serverDelay;
  let beginDate = new Date(beginDateOri).getTime();
  let endDate = new Date(endDateOri).getTime();

  if (curDate >= beginDate && curDate < endDate) {
    return true;
  }

  return false;
};
export const initEventData = () => {
  store.dispatch("setEventDetail", null);
  store.dispatch("setEventExpress", "");
  store.dispatch("setEventId", "");
  store.dispatch("setJointPhoto", null);
  store.dispatch("setIsBan", false);
  store.dispatch("setTrtcData", {
    key: "client",
    value: null
  });
  store.dispatch("setTrtcData", {
    key: "remoteStream",
    value: null
  });
  store.dispatch("setTrtcData", {
    key: "localStream",
    value: null
  });
  store.dispatch("setTrtcData", {
    key: "streamingInfo",
    value: null
  });
  localStorage.removeItem("eventType");
};
export const getEvent = () => {
  const router = useRouter();
  const route = useRoute();
  const haveTheme = route.meta.haveTheme;

  const joined = () => {
    authApi.events().then(res => {
      var _store$getters$eventD;

      if (res.data.events.length === 0) {
        router.replace({
          name: "EnterCode"
        });
        return false;
      }

      const {
        eventExpress,
        eventId
      } = res.data.events[0];
      const vuexEventId = (_store$getters$eventD = store.getters.eventDetail) === null || _store$getters$eventD === void 0 ? void 0 : _store$getters$eventD.eventId;

      if (vuexEventId !== eventId) {
        reTryGetEvent(eventId);
      } else {
        if (haveTheme) {
          setEventTheme();
        }
      }

      store.dispatch("setEventExpress", eventExpress || "");
      store.dispatch("setEventId", eventId || "");
    }).catch(error => {
      console.error(error);
      router.replace({
        name: "EnterCode"
      });
    });
  };

  const reTryGetEvent = id => {
    authApi.eventsDetail(id).then(res => {
      store.dispatch("setEventDetail", res.data);
      localStorage.setItem("eventType", res.data.eventType);

      if (haveTheme) {
        setEventTheme();
      }
    }).catch(error => {
      console.error(error);
      window.$toast.error("参加できるイベントがないです", {
        position: "top"
      });
      router.replace({
        name: "EnterCode"
      });
    });
  };

  joined();
};
export const checkEventTheme = () => {
  if (store.getters.eventDetail) {
    setEventTheme();
  }
};
export const setEventTheme = () => {
  var _store$getters$eventD2, _store$getters$eventD3;

  const themeData = (_store$getters$eventD2 = store.getters.eventDetail) === null || _store$getters$eventD2 === void 0 ? void 0 : _store$getters$eventD2.theme;
  const eventId = (_store$getters$eventD3 = store.getters.eventDetail) === null || _store$getters$eventD3 === void 0 ? void 0 : _store$getters$eventD3.eventId;

  if (themeData && eventId) {
    if (weshowThemeEventId === eventId) {
      console.log('theme is already set');
      return;
    }

    setTheme(themeData, eventId);
  } else {
    initTheme();
  }
};
export const setTheme = (themeData, eventId) => {
  if (eventId) {
    weshowThemeEventId = eventId;
  } else {
    weshowThemeEventId = null;
  }

  Object.keys(themeData).forEach(key => {
    document.body.style.setProperty(`--${key}`, themeData[key]);
  });
};
export const initTheme = () => {
  setTheme(initThemeData);
};
export const langList = [{
  label: '日本語',
  code: 'ja',
  subtitle: 'ja-JP',
  azureSpeech: {
    translationLanguage: 'ja',
    recognitionLanguage: 'ja-JP'
  }
}, {
  label: '中文',
  code: 'zh',
  subtitle: 'zh-CN',
  azureSpeech: {
    translationLanguage: 'zh-Hans',
    recognitionLanguage: 'zh-CN'
  }
}, {
  label: '한국인',
  code: 'ko',
  subtitle: 'ko-KR',
  azureSpeech: {
    translationLanguage: 'ko',
    recognitionLanguage: 'ko-KR'
  }
}, {
  label: 'English',
  code: 'en',
  subtitle: 'en-US',
  azureSpeech: {
    translationLanguage: 'en',
    recognitionLanguage: 'en-US'
  }
}]; // https://www.techonthenet.com/js/language_tags.php

export const getLang = () => {
  const cacheLocale = localStorage.getItem('locale');
  let lang = navigator.language || navigator.userLanguage;

  if (cacheLocale) {
    lang = cacheLocale;
  } else {
    lang = lang.substr(0, 2);
  }

  const data = langList.find(item => {
    return item.code === lang;
  });

  if (!data) {
    lang = 'en';
  }

  return lang;
};
export const logErrorToText = error => {
  const errorType = typeof error;

  if (errorType === 'string') {
    return error;
  }

  if (errorType === 'object') {
    if (error instanceof Error) {
      return error.message;
    }
  }

  return null;
};