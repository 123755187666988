import { computed, ref, onBeforeMount, onUnmounted, watch, inject, onMounted, nextTick } from 'vue';
import store from '@/store';
import Loading from '@/components/Loading.vue';
import { useRoute, useRouter } from 'vue-router';
import UAParser from 'ua-parser-js';
import { useI18n } from 'vue-i18n';
import Alert from '@/components/Alert.vue';
export default {
  components: {
    Loading,
    Alert
  },

  setup() {
    const {
      t
    } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const toast = inject('toast');
    let resizeTimer = null; // const winHeight = ref(0);

    const getWinHeight = () => {
      // winHeight.value = window.innerHeight;
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        store.dispatch('setWinHeight', window.innerHeight);
        store.dispatch('setWinWidth', window.innerWidth);
        console.log(window.innerHeight);
      }, 200);
    };

    const loadingShow = computed(() => {
      return store.getters.loadingShow;
    });
    const bgStyle = computed(() => {
      return {
        height: store.getters.winHeight + 'px'
      };
    }); // watch(token, (val) => {
    //   if (val) {
    //     joined();
    //   }
    // });

    let appCheckDevicePromiseResolve = null;
    let appCheckDevicePromiseReject = null;
    const appCheckDevicePromise = new Promise((resolve, reject) => {
      appCheckDevicePromiseResolve = resolve;
      appCheckDevicePromiseReject = reject;
    });
    store.dispatch('setAppCheckDevicePromise', appCheckDevicePromise);

    const checkDevice = () => {
      var _window$webkit, _res$os$name, _res$browser$name;

      const messageHandlers = (_window$webkit = window.webkit) === null || _window$webkit === void 0 ? void 0 : _window$webkit.messageHandlers;

      if (messageHandlers) {
        goErrorPage();
        return;
      }

      const parser = new UAParser();
      const res = parser.getResult();
      console.log(res);
      const os = (_res$os$name = res.os.name) === null || _res$os$name === void 0 ? void 0 : _res$os$name.toLowerCase();
      const browser = (_res$browser$name = res.browser.name) === null || _res$browser$name === void 0 ? void 0 : _res$browser$name.toLowerCase();
      const broswerMajor = parseInt(res.browser.major);
      const browserList = {
        windows: ['chrome', 'edge'],
        'mac os': ['chrome', 'safari'],
        android: ['chrome'],
        ios: ['safari', 'mobile safari']
      };
      let browserListByOS = null;

      switch (os) {
        case 'windows':
        case 'mac os':
        case 'android':
        case 'ios':
          browserListByOS = browserList[os];
          break;

        default:
          goErrorPage();
          appCheckDevicePromiseReject('os error');
          return;
      }

      if (!browserListByOS) {
        goErrorPage();
        appCheckDevicePromiseReject('browserListByOS error');
        return;
      } else {
        let browserIndex = -1;
        browserIndex = browserListByOS.indexOf(browser);

        if (browserIndex === -1) {
          goErrorPage();
          appCheckDevicePromiseReject('browser error');
          return;
        } else {
          const broswerMinMajorList = {
            windows: {
              chrome: 56,
              edge: 80
            },
            'mac os': {
              chrome: 56,
              safari: 11
            },
            android: {
              chrome: 0
            },
            ios: {
              safari: 11,
              'mobile safari': 11
            }
          };
          const minMajor = broswerMinMajorList[os][browser];

          if (minMajor > broswerMajor) {
            goErrorPage();
            appCheckDevicePromiseReject('broswerMajor error');
            return;
          }
        }
      }

      appCheckDevicePromiseResolve();
    };

    const goErrorPage = () => {
      nextTick(() => {
        if (route.name === 'Error') {
          return;
        }

        const pageType = route.meta.pageType;
        console.log(route); // debugger

        const messages = ['UAParserCheckError'];

        if (pageType === 'Ticket') {
          messages.push('TicketUAParserCheckError');
        }

        router.replace({
          name: 'Error',
          query: {
            errorData: window.btoa(encodeURIComponent(JSON.stringify(messages)))
          }
        });
      });
    }; // 由于app.vue中的route尚未初始化完毕，所以需要在watch中检查设备


    const isCheckedDevice = ref(false);
    const checkDelayIsError = computed(() => {
      return store.getters.checkDelayIsError;
    });
    const reflushing = ref(false);

    const reload = () => {
      reflushing.value = true; // 页面刷新

      window.location.reload();
    };

    watch(route, val => {
      if (!isCheckedDevice.value) {
        checkDevice();
        isCheckedDevice.value = true;
      }
    });
    onBeforeMount(() => {
      getWinHeight();
      window.addEventListener('resize', getWinHeight);
    });
    onMounted(() => {
      document.addEventListener('touchstart', function (event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      });
      document.addEventListener('gesturestart', function (event) {
        event.preventDefault();
      }); // setInterval(() => {
      //   console.log(window.innerHeight);
      // }, 1000);
    });
    onUnmounted(() => {
      window.removeEventListener('resize', getWinHeight);
    });
    return {
      bgStyle,
      loadingShow,
      isCheckedDevice,
      // 时间校验出错
      checkDelayIsError,
      reflushing,
      reload
    };
  }

};