export const m = {
  // login
  pleaseInputNickName: 'Please input nick name',
  pleaseInputNickNameTip: 'Please enter a nickname of up to 12 characters',
  pleaseInputCode: 'Please enter the verification code',
  certification: 'certification',
  useGuideClick: 'Click here for usage guide',
  noEventTip: 'There are no events to attend',
  certificationFailed: 'certification failed',
  subtitleTip: 'Please select a subtitle language',
  // device test
  deviceTest: 'Device test',
  permissionPermitTip: 'Please allow camera and microphone permissions.',
  deviceToConnection: 'Connecting to device',
  deviceTestStartTip: 'Device and network connection was successful. Please start the test.',
  deviceTestFailedTip: 'Device and network connection failed. Please check the equipment again.',
  deviceTestStart: 'Test start',
  skip: 'skip',
  cameraSelection: 'camera selection',
  startingCamera: 'Starting camera',
  testCameraTip: 'Is your camera up?',
  speakerSelection: 'speaker selection',
  devicePlayMusicTip: 'Play button plays music.',
  devicePlayMusicTip2: 'Please adjust the volume.',
  testMicTip: `Let's talk something`,
  micSelection: 'microphone selection',
  testMicTip2: 'Does the pink bar move when you speak?',
  netStatus: 'net situation',
  inTest: 'in test',
  netTestTip: 'Testing. It may take about 1-2 minutes. If the screen does not switch automatically after a while, the network status may be unstable.',
  netTestTip2: 'Please check your network environment and try reloading or restarting your browser.',
  netResTip: 'Current network status: Good',
  netResTip2: 'Please keep the network environment stable.',
  netResTip3: 'Current network state: Not very good',
  netResTip4: 'Conversation may be delayed, so please move to a place with a good network environment.',
  netResTip5: 'Current Network State: Error',
  netResTip6: 'Network not found. Please move or check the recommended environment.',
  netResTip7: 'Current network state: very bad',
  netResTip8: 'You may not be able to talk, so be sure to move to a place with a good network environment.',
  toTestResult: 'Go to test results',
  testResult: 'test results',
  camera: 'camera',
  speaker: 'speaker',
  microphone: 'microphone',
  checkOk: 'ok',
  checkFailed: 'failed',
  unknown: 'unknown',
  good: 'good',
  notGood: 'not good',
  error: 'error',
  bad: 'bad',
  redo: 'redo',
  completed: 'completion',
  devicePageErrorTip: 'No terminal or browser available.',
  devicePageErrorTip2: 'Please check the recommended environment.',
  devicePageErrorTip3: 'Recommended environment',
  devicePageErrorTip4: 'Windows 10 Latest version of Google Chrome, Microsoft Edge',
  devicePageErrorTip5: '※Does not support Internet Explorer',
  devicePageErrorTip6: 'macOS 11.0 or higher (Latest version of Google Chrome, Safari)',
  devicePageErrorTip7: 'Smart phone/Tablet',
  devicePageErrorTip8: 'Android 8 or higher (latest version of Google Chrome)',
  devicePageErrorTip9: 'iOS 12.0 or higher (latest version of Safari)',
  devicePageErrorTip10: 'Smart phone',
  devicePageErrorTip11: 'iPhone：iPhone7 or later (including 7)',
  devicePageErrorTip12: 'Android：Devices released within the last three years',
  devicePageErrorTip13: 'Remarks: Not compatible with Huawei devices (because there are functions that are restricted in use)',
  devicePageRemote: "remote",
  devicePageLocal: "local",
  devicePageTrtcTestTip: 'Can both the local and remote be seen?',
  devicePageTrtcTestTip2: 'Can you hear your own voice when you speak?',
  // Entrance
  shootingGuideTip: '<Shooting guide>',
  shootingGuideTip2: 'pick up your phone',
  shootingGuideTip3: 'keep your hands straight',
  shootingGuideTip4: 'Adjust the talent and pose along the white frame',
  shootingGuideTip5: '※important point',
  shootingGuideTip6: 'use your smart phone',
  shootingGuideTip7: `don't put it on the desk`,
  shootingGuideTip8: 'You can take pictures like this!',
  shootingGuideTip9: '<Notes>',
  shootingGuideTip10: 'read the notes and',
  shootingGuideTip11: '10 minutes before {eventExpress} starts, you can press the "Agree and continue" button.',
  shootingGuideTip12: 'Please be sure to wait in the waiting room until {eventExpress} starts. Please refrain from switching between apps or browsers while keeping the screen open as it may cause network instability.',
  shootingGuideTip13: 'If you leave the site for unavoidable reasons and the screen does not appear when you return, please refresh the page.',
  shootingGuideTip14: '<Prohibited matter>',
  shootingGuideTip15: 'Please refrain from slander, swearing, or other behavior that could be taken as harassment, device recording, or screenshots.',
  shootingGuideTip16: 'I will record some for quality improvement. Thank you for your understanding.',
  // プライバシーポリシー
  privacyPolicyTitle: 'Privacy Policy',
  agreementNext: 'Agree and continue',
  // PositionSelect
  leftRightPositionSelection: 'Left/right position selection',
  left: 'left',
  right: 'right',
  leftRightDemoTip: '※For demo purposes, only the right side can be selected.',
  positionSelectTip: 'First, determine the left and right positions.',
  // ChatRoom
  chatRoomTip: 'Please wait for a while until the start',
  chatRoomTip2: 'display message limit reached',
  chatRoomTip3: 'No more messages',
  chatRoomErrorTip: 'Remarks were rejected due to judging rules',
  sendComment: 'To comment',
  chatRoomName: `{nickName}'s chat room`,
  // meetIndex
  meetIndexErrorTip: 'Make sure to allow camera and microphone access',
  meetIndexErrorTip2: 'A network or device error has occurred. Please reload the page.',
  meetIndexErrorTip3: 'A camera or microphone error has occurred. Please reload the page.',
  meetIndexErrorTip4: 'An error has occurred in the video or audio.',
  meetIndexErrorTip5: 'A camera or microphone error has occurred. Please allow it again.',
  meetIndexErrorTip6: '<div class="tl">Your camera and microphone are blocked<br />Click the browser Refresh icon<img src="/img/rotate.png" height="20" /> to refresh the page.</div>',
  meetIndexErrorTip7: `<div class="tl">Your camera and microphone are blocked<br />You must allow access to your camera and microphone. Click the blocked "lock" icon <img src="/img/lock.png" height="20" /> in your browser's address bar, then click "Permissions" to open the camera and microphone please.</div>`,
  meetIndexErrorTip8: 'Your two-shot has expired!',
  meetIndexErrorTip9: 'Microphone initialization failed',
  // 无法进入下一阶段，请刷新页面后再次尝试。
  meetIndexErrorTip10: 'Unable to enter the next stage, please try again after refreshing the page.',
  // Meeting
  meetingTip: 'Network is unstable.',
  meetingTip2: 'Please confirm.',
  meetingTip3: 'After this, go to the two-shot shooting room',
  // ShootingRoom
  shootingRoomTip: 'Two-shot shooting room',
  shootingRoomTip2: 'Get ready to shoot.',
  shootingRoomTip3: 'After the countdown ends, shooting will be performed automatically.',
  shootingRoomTip4: 'Two photographs are taken in succession.',
  // WaittingRoom
  waittingRoomStatus: 'Waiting',
  waittingRoomCameraTitle: 'camera footage',
  waittingRoomMicTest: 'mic test',
  waittingRoomVoiceTest: 'volume test',
  waittingRoomTip: '*Notice',
  waittingRoomTip2: `Please do not use your device's recording function or screenshots.`,
  waittingRoomTip3: 'Check the microphone/speaker icon above for final confirmation.',
  waittingRoomTip4: 'Tap the volume test button and adjust the volume',
  waittingRoomTip5: 'Images and text do not need to be flipped, please show them correctly.',
  waittingRoomTip6: 'In a microphone test, it responds when you make a sound.',
  // Cert
  certEventEnd: '{eventExpress} ended',
  certEventEndTip: 'Thank you',
  certErrorTip: 'Sign failed!',
  certErrorTip2: 'Please contact us using the form below',
  certErrorTip3: 'Your signature has expired!',
  certErrorTip4: 'sign failed',
  certTip: 'Save the image and brag to everyone on SNS etc.!',
  certTip2: 'Click here for inquiries about {eventExpress}',
  certTip3: 'Rate {eventExpress}',
  certMarking: 'making',
  certificate: ' certificate',
  joinTime: 'time',
  liverEvent: '{eventExpress} on {nickName}',
  mrMiss: '{nickName}',
  // EditPhoto
  editPhotoTitle: 'Two-shot editing room',
  editPhotoTip: 'Select an object and edit it.',
  editPhotoTip2: 'Check the image below',
  standardStyle: 'Standard style',
  particularAdjustment: 'fine adjustment',
  positionAdjustment: 'Positioning',
  dark: 'dark',
  cold: 'cold',
  warm: 'warm',
  bright: 'bright',
  brightness: 'brightness',
  contrast: 'contrast',
  saturation: 'saturation',
  editComplete: 'edit done',
  editPhotoErrorTip: 'Failed to crop photo',
  editPhotoErrorTip2: 'The management office will investigate whether it is possible to synthesize a two-shot photo.',
  editPhotoErrorTip3: 'Please contact us using the form below',
  // SavePhoto
  savePhotoTip: 'A two-shot has been issued! If you are using a smartphone, long press the image to save it to your device.',
  savePhotoTip2: 'This page is valid until {expirationTime}.',
  savePhotoTip3: 'If you cannot save, please click the button below.',
  savePhotoTip4: 'open image',
  savePhotoTip5: 'Saving',
  savePhotoErrorTip: 'Contact Us',
  savePhotoErrorTip2: 'Click here for viewing issues',
  savePhotoDemoExp: '12/30/2125',
  // SelectPhoto
  selectPhotoTitle: 'Two-shot editing room',
  selectPhotoTip: 'Please choose your favorite one.',
  selectPhotoErrorTip: 'Failed to take two shots and upload photos',
  selectPhotoErrorTip2: 'The management office will investigate whether it is possible to synthesize a two-shot photo.',
  selectPhotoErrorTip3: 'Please contact us using the form below',
  // Inquiry
  inquiryEventEnd: '{eventExpress} ended',
  inquiryEventEndTip: 'Thank you',
  // Evaluate
  evaluateTip: `How's the audio and video quality?`,
  evaluateGood: 'Very good',
  evaluateBad: 'Very bad',
  // LiveEntrance
  liveEntranceTitle: 'Live broadcast',
  liveEntranceTip: 'Unauthorized recording/recording of the distribution content, and posting/reprinting to places where an unspecified number of people can view (including SNS etc.) are prohibited.',
  liveEntranceTip2: 'If such acts are discovered and the organizer deems it necessary, we may ask for the cooperation of investigative authorities, such as reporting to the police.',
  liveEntranceTip3: 'Please observe the manners and enjoy yourself.',
  liveEntranceBtn: 'agree and watch',
  // LiveWait
  liveWait: 'Live in preparation',
  liveWaitTip: 'If it has not been played for a long time, please reload the page.',
  // LiveStreaming
  liveStreamingTitle: 'Live stream "{title}"',
  liveStreamingStartTime: 'Live distribution start date',
  liveStreamingWatchNewComment: 'see new comments',
  liveStreamingGift: '{nickName} gave {giftName}',
  liveStreamingGift2: 'gave {giftName}',
  liveStreamingWelcome: 'Welcome to WeShoW Livestreams.',
  liveStreamingTip: 'Click the button and go live!',
  liveStreamingWording1: 'Please wait while the live stream is loading.',
  liveStreamingWording2: 'Please wait while the live stream is loading.',
  liveStreamingWording3: 'Video decoding error.',
  liveStreamingWording4: 'The current system environment does not support playback of this video format.',
  liveStreamingWording5: 'The current system environment does not support playback of this video format.',
  liveStreamingWording10: `Don't use the player with the file protocol. The video may become unplayable.`,
  liveStreamingWording11: 'Wrong usage parameters. Check your player calling code.',
  liveStreamingWording12: 'Please enter the video playback address.',
  liveStreamingWording13: 'The live stream has ended, so please come back later.',
  liveStreamingWording1001: 'network error. Check if your network settings or links are correct.',
  liveStreamingWording1002: 'Failed to get video. Please check if the link is valid.',
  liveStreamingWording2001: 'Please wait while the live stream is loading.',
  liveStreamingWording2002: 'Please wait while the live stream is loading.',
  liveStreamingWording2003: 'Not live streamed.',
  liveStreamingWording2032: 'Failed to get video. Please check if the link is valid.',
  liveStreamingWording2048: 'Unable to load video file. Cross-domain access is denied.',
  liveStreamingErrorTip: 'You were logged out forcibly because you are logged in elsewhere.',
  liveStreamingErrorTip2: 'Remarks were rejected due to judging rules',
  // LiveEnd
  liveEnd: 'LIVE has ended',
  // CheckOrder
  checkOrderTip: 'Please enter your email',
  checkOrderTip2: 'Please enter your order number',
  checkOrderTip3: '※The order number is listed in the purchase completion email.',
  checkOrderErrorTip: 'Please enter a valid email',
  checkOrderErrorTip2: 'set order number',
  // CheckOrderDetail
  checkOrderDetailErrorTip: `Error`,
  checkOrderDetailErrorTip2: `Unable to confirm purchase contents.
Please check your email address and order number again.`,
  checkOrderDetailTip: 'Purchase Contents',
  // PayEnd
  payEndErrorTip: `Error`,
  payEndErrorTip2: `can't get the payment result,`,
  payEndErrorTip3: 'The payment result will be sent to the registered Email: {email} at a later date.',
  payEndErrorTip4: 'The sending email address may be put in the trash.',
  payEndTip: 'Purchase Contents',
  payEndTip2: 'The above information will be sent to the registered Email: {email}.',
  // PayEvent
  remainingCount: 'Remaining number',
  eventIntroduction: 'Event introduction',
  liverIntroduction: 'Liver introduction',
  contactUs: 'inquiry',
  toWebContactUs: 'Contact us via web form',
  payEventContactUsTip: 'Inquiries will be accepted only for applications and participation methods for this event.',
  payEventContactUsTip2: 'Please note that we cannot reply to questions to individual performers or details of the event content.',
  payEventContactUsTip3: 'Inquiry hours: Weekdays 10:00-18:00',
  inputScreen: 'input screen',
  verificationScreen: 'confirmation screen',
  eventTitle: 'event name',
  personallyIdentifiableInformation: 'personal information',
  purchasePrice: 'Purchase price',
  payEventTip: 'order whose payment has not yet been confirmed. Would you like to cancel the order?',
  payEventTip2: 'Please enter your email',
  payEventTip3: 'Please enter the number of purchases',
  payEventTip4: 'Please re-enter your email address',
  payEventTip5: '※Please re-enter for confirmation.',
  reconfirmation: 'reconfirmation',
  payEventErrorTip: 'Please enter your name',
  payEventErrorTip2: 'Please enter a valid email',
  payEventErrorTip3: 'Out of stock',
  payEventErrorTip4: 'Purchase Limit Exceeded',
  payEventErrorTip5: 'The email addresses entered twice do not match',
  payEventErrorTip6: 'Please enter your confirmation email address',
  payEventErrorTip7: 'Insufficient remaining quantity, please re-enter.',
  payMaxOnce: 'Maximum quantity per purchase',
  // ua check error
  uaCheckErrorTitle: 'The device or browser you are currently accessing cannot be used.',
  uaCheckErrorTitle2: `We apologize for the inconvenience. 
The device or browser you are currently using cannot be used for application.`,
  uaCheckErrorTip: 'Please confirm your recommended environment and copy and paste the following URL in the recommended environment before accessing again.',
  uaCheckErrorTip2: 'Please copy the sales URL of the product you wish to apply for and access it again in the recommended environment below.',
  // common
  return: 'return',
  no: 'no',
  yes: 'yes',
  fan: 'fan',
  errorTip: 'An error has occurred',
  remaining: 'over ',
  remaining2: 'over ',
  user: 'user',
  day: 'day',
  hours: 'hours',
  minutes: 'minutes',
  seconds: 'seconds',
  // 简写的时间
  d: 'd',
  h: 'h',
  m: 'm',
  s: 's',
  YYYYMMDD: 'MM/DD/YYYY',
  aboutTime: 'about ',
  more: 'more ',
  send: 'send',
  reload: 'reload',
  recovery: 'recovery',
  permission: 'permission',
  redo: 'redo',
  sendCompleted: 'Sent',
  sendError: 'failed sending',
  toNext: 'to the next',
  toInquiry: 'Contact us',
  save: 'save',
  check: 'check',
  toOrderInquiry: 'Confirm Purchase Contents',
  photoProcessing: 'Photo processing in progress',
  eventInfo: 'event information',
  title: 'title',
  liver: 'liver',
  schedule: 'schedule',
  start: 'start',
  end: 'end',
  notice: 'Notes',
  reservationNumber: 'Number of reservations',
  ticketsNumber: '{count}tickets',
  tickets: 'tickets',
  authCode: 'authentication code',
  cautions: 'Notes',
  distributor: 'Distributor',
  isCancel: 'canceled',
  ticketDetail: 'Ticket details',
  eventDetail: 'Event details',
  inConfirmation: 'Confirming',
  EBCompanyName: '株式会社イー・ビジネス',
  joinUrl: 'Participation URL',
  useGuide: 'User guide',
  buy: 'buy',
  cantBuy: 'Outside sales hours',
  isNoStock: 'Sold out',
  name: 'name',
  userAgree: `I agree to the <a
  href="https://guide.weshow.co.jp/terms_user"
  target="_blank"
  class="cInherit unl"
  >User Terms of Service</a
  >`,
  policyAgree: `I agree to the <a
  href="https://guide.weshow.co.jp/privacy_policy"
  target="_blank"
  class="cInherit unl"
  >privacy policy</a
  >`,
  termsUser: `User Terms of Service`,
  privacyPolicy: `Privacy Policy`,
  termsLaw: `Notation based on the Act on Specified Commercial Transactions`,
  loading: 'loading',
  // 暂停中
  liveIsPaused: 'Live is paused',
  // 只今、準備中です。<br />再開するまでお待ちください。
  liveIsPreparing: 'Live is preparing.<br />Please wait until it restarts.',
  // 了解
  know: 'OK',
  // 时间校准失败请重新刷新页面
  checkDelayError: 'Time calibration failed. Please refresh the page.',
  // 时间校准失败请确认网络环境后再次尝试
  checkDelayError2: 'Time calibration failed. Please check your network environment and try again.'
};