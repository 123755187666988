import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "app-bg"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");

  const _component_Loading = _resolveComponent("Loading");

  const _component_alert = _resolveComponent("alert");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_view), $setup.loadingShow ? (_openBlock(), _createBlock(_component_Loading, {
    key: 0,
    text: _ctx.$t('m.loading')
  }, null, 8, ["text"])) : _createCommentVNode("", true), _createVNode(_component_alert, {
    isOpen: $setup.checkDelayIsError,
    text: _ctx.$t('m.checkDelayError'),
    "ok-text": _ctx.$t('m.reload'),
    isLoading: $setup.reflushing,
    onOk: $setup.reload,
    "has-close": false
  }, null, 8, ["isOpen", "text", "ok-text", "isLoading", "onOk"])]);
}