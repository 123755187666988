export const m = {
  // login
  pleaseInputNickName: '请输入昵称',
  pleaseInputNickNameTip: '请输入12个文字以内的昵称',
  pleaseInputCode: '请输入认证码',
  certification: '认证',
  useGuideClick: '点击查看使用说明',
  noEventTip: '没有可参加的活动',
  certificationFailed: '认证失败',
  subtitleTip: '请选择语音字幕语言',
  // device test
  deviceTest: '设备检测',
  permissionPermitTip: '请授权摄像头与麦克风权限。',
  deviceToConnection: '设备连接中',
  deviceTestStartTip: '已成功连接设备与网络。请开始测试。',
  deviceTestFailedTip: '连接设备与网络失败。请再次检测设备。',
  deviceTestStart: '测试开始',
  skip: '跳过',
  cameraSelection: '选择摄像头',
  startingCamera: '摄像头启动中',
  testCameraTip: '摄像头启动了吗？',
  speakerSelection: '选择播放设备',
  devicePlayMusicTip: '点击按钮会播放音乐。',
  devicePlayMusicTip2: '请调节音量。',
  testMicTip: '说点什么吧',
  micSelection: '选择麦克风',
  testMicTip2: '发出声音时色块有变化吗？',
  netStatus: '网络状况',
  inTest: '测试中',
  netTestTip: '测试中，可能需要1到2分钟。如果长时间没有反应的话可能是网络状况不稳定。。',
  netTestTip2: '请确认网络环境并刷新页面或者重启浏览器。',
  netResTip: '当前网络状况：良好',
  netResTip2: '请保持当前网络环境。',
  netResTip3: '当前网络状况：不佳',
  netResTip4: '对话会发生延迟，请移动到网络环境好一点的地方。',
  netResTip5: '当前网络状况：错误',
  netResTip6: '检测不到网络。请更换网络或确认推荐环境。',
  netResTip7: '当前网络状况：非常差',
  netResTip8: '可能会出现无法对话的情况，请务必移动到网络环境好一点的地方。',
  toTestResult: '查看测试结果',
  testResult: '测试结果',
  camera: '摄像头',
  speaker: '播放设备',
  microphone: '麦克风',
  checkOk: '正常',
  checkFailed: '异常',
  unknown: '未知',
  good: '良好',
  notGood: '不佳',
  error: '错误',
  bad: '差',
  redo: '重来',
  completed: '结束',
  devicePageErrorTip: '设备或者浏览器不支持',
  devicePageErrorTip2: '请确认推荐环境。',
  devicePageErrorTip3: '推荐环境',
  devicePageErrorTip4: 'Windows10 最新版的Google Chrome、Microsoft Edge',
  devicePageErrorTip5: '※不支持Internet Explorer',
  devicePageErrorTip6: 'macOS11.0以上 (最新版的Google Chrome、Safari)',
  devicePageErrorTip7: '智能手机・平板电脑',
  devicePageErrorTip8: 'Android 8 以上(最新版的Google Chrome)',
  devicePageErrorTip9: 'iOS 12.0以上(最新版的Safari)',
  devicePageErrorTip10: '手机设备',
  devicePageErrorTip11: 'iPhone：iPhone7以上（含7）',
  devicePageErrorTip12: 'Android：近3年内的设备',
  devicePageErrorTip13: '注意：无法支持华为设备（有机能使用限制）',
  devicePageRemote: "配信图像",
  devicePageLocal: "原始图像",
  devicePageTrtcTestTip: '原始图像和配信图像能不能都看到摄像头的映像？',
  devicePageTrtcTestTip2: '说话时能不能听见自己的声音？',
  // Entrance
  shootingGuideTip: '〈拍摄指南〉',
  shootingGuideTip2: '将手机抬高',
  shootingGuideTip3: '将手伸开',
  shootingGuideTip4: '沿着白边和主播一起调整姿势',
  shootingGuideTip5: '※注意点',
  shootingGuideTip6: '请使用智能手机',
  shootingGuideTip7: '不要摆在桌子上',
  shootingGuideTip8: '就可以拍下如下的照片！',
  shootingGuideTip9: '〈注意事项〉',
  shootingGuideTip10: '请阅读注意事项',
  shootingGuideTip11: '{eventExpress}开始10分钟前才可以点击「同意并下一步」按钮。',
  shootingGuideTip12: '{eventExpress}距离开始前请务必在等待室等候。由于可能会导致网络不安定，请保持画面打开状态，期间不要切换浏览器和应用。',
  shootingGuideTip13: '遇到有不得不离开的情况，回来后无法显示画面的话，请重新刷新页面。',
  shootingGuideTip14: '〈禁止事项〉',
  shootingGuideTip15: '禁止诽谤中伤，骂人或其他让人困扰的行为。禁止使用设备内的录像功能，截图功能。',
  shootingGuideTip16: '为提升品质，一部分信息将会被记录。请谅解。',
  // プライバシーポリシー
  privacyPolicyTitle: '隐私条款',
  agreementNext: '同意并下一步',
  // PositionSelect
  leftRightPositionSelection: '选择左右位置',
  left: '左',
  right: '右',
  leftRightDemoTip: '※案例页面，只能选择左侧',
  positionSelectTip: '首先请选择左右吧。',
  // ChatRoom
  chatRoomTip: '请稍等',
  chatRoomTip2: '显示信息已达到限制',
  chatRoomTip3: '没有更多信息了',
  chatRoomErrorTip: '依据规则，无法发送该信息',
  sendComment: '发表评论',
  chatRoomName: '{nickName}的聊天室',
  // meetIndex
  meetIndexErrorTip: '请务必允许摄像头与麦克风的权限',
  meetIndexErrorTip2: '网络或者设备发生错误，请刷新页面。',
  meetIndexErrorTip3: '网络或者设备发生错误，请刷新页面。',
  meetIndexErrorTip4: '画面或者声音发生错误。',
  meetIndexErrorTip5: '网络或者设备发生错误。请再次许可权限。',
  meetIndexErrorTip6: '<div class="tl">摄像头和麦克风被锁定<br />请点击浏览器上的「刷新」图标<img src="/img/rotate.png" height="20" />刷新页面</div>',
  meetIndexErrorTip7: '<div class="tl">摄像头和麦克风被锁定<br />必须要有摄像头和麦克风权限。点击浏览器地址栏上的「锁」图标<img src="/img/lock.png" height="20" />再点击「权限」打开摄像头和麦克风。</div>',
  meetIndexErrorTip8: '合照已过期！',
  meetIndexErrorTip9: '麦克风初始化失败',
  // 无法进入下一阶段，请刷新页面后再次尝试。
  meetIndexErrorTip10: '无法进入下一阶段，请刷新页面后再次尝试。',
  // Meeting
  meetingTip: '网络不稳定。',
  meetingTip2: '请确认。',
  meetingTip3: '这之后进入合照摄影室',
  // ShootingRoom
  shootingRoomTip: '合照摄影室',
  shootingRoomTip2: '准备拍照吧。',
  shootingRoomTip3: '倒计时结束后，将自动拍照。',
  shootingRoomTip4: '照片会连续拍摄两次。',
  // WaittingRoom
  waittingRoomStatus: '待机中',
  waittingRoomCameraTitle: '摄像头画面',
  waittingRoomMicTest: '麦克风测试',
  waittingRoomVoiceTest: '音量测试',
  waittingRoomTip: '*请注意',
  waittingRoomTip2: '请不要使用设备的录像功能和截图功能。',
  waittingRoomTip3: '通过上面的麦克风和播放设备进行最终确认。',
  waittingRoomTip4: '请点击音量测试按钮后对音量进行调整',
  waittingRoomTip5: '对方看到的画面和文字不会被反转，会正确显示给对方。',
  waittingRoomTip6: '发出声音时麦克风测试会有反应',
  // Cert
  certEventEnd: '{eventExpress}结束',
  certEventEndTip: '谢谢参加{eventExpress}！',
  certErrorTip: '签名失败！',
  certErrorTip2: '通过下面的表单进行询问',
  certErrorTip3: '签名有已过期 ！',
  certErrorTip4: '签名失败',
  certTip: '保存图像，发表到社交平台上吧！',
  certTip2: '关于{eventExpress}的问题在这里咨询',
  certTip3: '去评价{eventExpress}吧',
  certMarking: '制作中',
  certificate: '参加证',
  joinTime: '参加时间',
  liverEvent: '{nickName}的{eventExpress}',
  mrMiss: '{nickName}',
  // EditPhoto
  editPhotoTitle: '合照编辑',
  editPhotoTip: '选择然后进行编辑',
  editPhotoTip2: '确认下面的图像',
  standardStyle: '标准风格',
  particularAdjustment: '细节调整',
  positionAdjustment: '位置调整',
  dark: '暗',
  cold: '冷',
  warm: '暖',
  bright: '亮',
  brightness: '亮度',
  contrast: '对比度',
  saturation: '饱和度',
  editComplete: '完成编辑',
  editPhotoErrorTip: '抠图失败',
  editPhotoErrorTip2: '为了让运营方更快调查是否能够合成照片',
  editPhotoErrorTip3: '请在下方表单中提交问题',
  // SavePhoto
  savePhotoTip: '合照已发行！手机的情况下长按保存图片。',
  savePhotoTip2: '本页面截至{expirationTime}有效。',
  savePhotoTip3: '如果无法保存的话请点击下面的按钮。',
  savePhotoTip4: '打开图像',
  savePhotoTip5: '保存中',
  savePhotoErrorTip: '点击这里询问',
  savePhotoErrorTip2: '关于视听方面的问题点这里',
  savePhotoDemoExp: '2125年12月30日',
  // SelectPhoto
  selectPhotoTitle: '合照编辑',
  selectPhotoTip: '请选择喜欢的照片。',
  selectPhotoErrorTip: '合照照片上传失败',
  selectPhotoErrorTip2: '为了让运营方更快调查是否能够合成照片',
  selectPhotoErrorTip3: '请在下方表单中提交问题',
  // Inquiry
  inquiryEventEnd: '{eventExpress}结束',
  inquiryEventEndTip: '感谢参加！',
  // Evaluate
  evaluateTip: '声音和画面品质感觉怎么样？',
  evaluateGood: '非常好',
  evaluateBad: '非常差',
  // LiveEntrance
  liveEntranceTitle: 'Live 直播',
  liveEntranceTip: '请不要随意将直播内容进行录像，录音，或发表在社交媒体中。',
  liveEntranceTip2: '一旦发现此类现象，根据主办方的需要，将会进行报警处理。',
  liveEntranceTip3: '请在遵守礼仪的情况下尽情享受。',
  liveEntranceBtn: '同意并开始',
  // LiveWait
  liveWait: 'Live准备',
  liveWaitTip: '长时间没有播放的情况下请刷新页面。',
  // LiveStreaming
  liveStreamingTitle: '直播「{title} 」',
  liveStreamingStartTime: '直播开始日',
  liveStreamingWatchNewComment: '查看最新评论',
  liveStreamingGift: '{nickName}赠送了{giftName}',
  liveStreamingGift2: '赠送了{giftName}哦',
  liveStreamingWelcome: '欢迎来到WeShoW直播。',
  liveStreamingTip: '点击按钮，开始直播！',
  liveStreamingWording1: '正在读取直播，请稍等。',
  liveStreamingWording2: '正在读取直播，请稍等。',
  liveStreamingWording3: '视频解码错误。',
  liveStreamingWording4: '当前系统环境不支持该视频格式。',
  liveStreamingWording5: '当前系统环境不支持该视频格式。',
  liveStreamingWording10: '不要使用带有文件协议的播放器。视频可能无法播放。',
  liveStreamingWording11: '使用参数错误。检查您的播放器调用代码。',
  liveStreamingWording12: '请输入视频播放地址。',
  liveStreamingWording13: 'Live已结束，请再来哦。',
  liveStreamingWording1001: '网络错误。检查您的网络设置或链接是否正确。',
  liveStreamingWording1002: '获取视频失败。请检查链接是否有效。',
  liveStreamingWording2001: '正在读取直播，请稍等。',
  liveStreamingWording2002: '正在读取直播，请稍等。',
  liveStreamingWording2003: '当前没有直播',
  liveStreamingWording2032: '获取视频失败。请检查链接是否有效。',
  liveStreamingWording2048: '无法加载视频文件。跨域访问被拒绝。',
  liveStreamingErrorTip: '您被强制注销，因为您在其他地方登录。',
  liveStreamingErrorTip2: '依据规则，无法发送该信息',
  // LiveEnd
  liveEnd: 'LIVE已结束',
  // CheckOrder
  checkOrderTip: '请输入邮箱地址',
  checkOrderTip2: '请输入订单号',
  checkOrderTip3: '※订单号码将在购买完成时的电子邮件中提供。',
  checkOrderErrorTip: '请输入正确的邮箱地址',
  checkOrderErrorTip2: '请输入订单号',
  // CheckOrderDetail
  checkOrderDetailErrorTip: '错误',
  checkOrderDetailErrorTip2: `无法确认购买内容。
请再次检查您的电子邮件地址和订单编号。`,
  checkOrderDetailTip: '购买内容',
  // PayEnd
  payEndErrorTip: '错误',
  payEndErrorTip2: '因为无法确认到支付结果',
  payEndErrorTip3: '之后我们会将支付结果发送到所登记的Email：{email}中。',
  payEndErrorTip4: '发送的邮箱地址可能会进入垃圾邮件。',
  payEndTip: '购买内容',
  payEndTip2: '上述内容也会发送到Email： {email}。',
  // PayEvent
  remainingCount: '剩余数量',
  eventIntroduction: '活动介绍',
  liverIntroduction: '主播介绍',
  contactUs: '咨询',
  toWebContactUs: '通过web表单咨询',
  payEventContactUsTip: '本次活动仅接受申请及参加方式的咨询。',
  payEventContactUsTip2: '请注意，我们无法回复对个别表演者的问题或活动内容的详细信息。',
  payEventContactUsTip3: '咨询时间：工作日10:00～18:00',
  inputScreen: '输入画面',
  verificationScreen: '确认画面',
  eventTitle: '活动名',
  personallyIdentifiableInformation: '个人信息',
  purchasePrice: '购买价格',
  payEventTip: '有一个尚未确认付款的订单。想取消订单吗？',
  payEventTip2: '请输入邮箱',
  payEventTip3: '请输入购买数量',
  payEventTip4: '请重新输入您的电子邮件地址',
  payEventTip5: '※请重新输入以进行确认。',
  reconfirmation: '再次确认',
  payEventErrorTip: '请输入名字',
  payEventErrorTip2: '请输入正确的邮箱地址',
  payEventErrorTip3: '缺货',
  payEventErrorTip4: '超过购买限制',
  payEventErrorTip5: '两次输入的邮箱地址不一致',
  payEventErrorTip6: '请输入确认邮箱地址',
  payEventErrorTip7: '剩余数量不足，请重新输入。',
  payMaxOnce: '单次购买限制',
  // ua check error
  uaCheckErrorTitle: '无法支持当前访问的设备或者浏览器。',
  uaCheckErrorTitle2: `非常抱歉。
目前您所使用的设备或浏览器无法进行申请。`,
  uaCheckErrorTip: '请确认推荐环境，并在推荐环境下复制并粘贴以下URL，然后再次访问。',
  uaCheckErrorTip2: '请复制您希望申请的产品销售链接，并在以下推荐的环境中再次访问。',
  // common
  return: '返回',
  no: '否',
  yes: '是',
  fan: '粉丝',
  errorTip: '发生错误',
  remaining: '还有',
  remaining2: '剩下',
  user: '用户',
  day: '日',
  hours: '小说',
  minutes: '分',
  seconds: '秒',
  // 简写的时间
  d: '日',
  h: '小时',
  m: '分',
  s: '秒',
  YYYYMMDD: 'YYYY年MM月DD日',
  aboutTime: '约',
  more: '查看更多',
  send: '发送',
  reload: '刷新',
  recovery: '恢复',
  permission: '许可',
  redo: '重来',
  sendCompleted: '已发送',
  sendError: '发送失败',
  toNext: '下一步',
  toInquiry: '咨询',
  save: '保存',
  check: '检查',
  toOrderInquiry: '确认购买内容',
  photoProcessing: '照片处理中',
  eventInfo: '活动情报',
  title: '标题',
  liver: '出演者',
  schedule: '日程',
  start: '开始',
  end: '结束',
  notice: '通知',
  reservationNumber: '预约数量',
  ticketsNumber: '{count}张',
  tickets: '张',
  authCode: '认证码',
  cautions: '注意事项',
  distributor: '贩卖者',
  isCancel: '已取消',
  ticketDetail: '出票详情',
  eventDetail: '活动详情',
  inConfirmation: '确认中',
  EBCompanyName: '株式会社イー・ビジネス',
  joinUrl: '参加URL',
  useGuide: '使用说明',
  buy: '购买',
  cantBuy: '不在贩卖时间内',
  isNoStock: '售罄',
  name: '名字',
  userAgree: `我同意<a
  href="https://guide.weshow.co.jp/terms_user"
  target="_blank"
  class="cInherit unl"
  >用户使用条款</a
>`,
  policyAgree: `我同意<a
href="https://guide.weshow.co.jp/privacy_policy"
target="_blank"
class="cInherit unl"
>隐私策略</a
>`,
  termsUser: `用户使用条款`,
  privacyPolicy: `隐私策略`,
  termsLaw: `依据特定商业交易法的标注`,
  loading: '加载中',
  // 暂停中
  liveIsPaused: '暂停中',
  // 只今、準備中です。<br />再開するまでお待ちください。
  liveIsPreparing: '正在准备中。<br />请稍等。',
  // 了解
  know: '知道了',
  // 时间校准失败请重新刷新页面
  checkDelayError: '时间校准失败，请重新刷新页面',
  // 时间校准失败请确认网络环境后再次尝试
  checkDelayError2: '时间校准失败，请确认网络环境后再次尝试'
};