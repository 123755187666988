<template>
  <Modal
    max-width="320px"
    :modelValue="isOpen"
    @update:modelValue="closeDialog($event)"
    :align-y="alignY"
    :clickOut="clickOut"
    modal-transition="slide-up"
    @afterLeave="afterLeave"
  >
    <div class="common-alert-body">
      <ion-icon
        icon="closeOutline"
        class="common-alert-close-ico"
        @click="close"
        v-if="hasClose"
      ></ion-icon>
      <div v-if="useHTML" v-html="text"></div>
      <div v-else>{{ text }}</div>
      <div v-if="type === 'confirm'" key="confirm" class="clearfix">
        <button type="button" class="common-alert-btn type-2 fl" @click="ok">
          {{ okText }}
        </button>
        <button
          type="button"
          class="common-alert-btn type-2 fr"
          @click="cancel"
        >
          {{ cancelText }}
        </button>
      </div>
      <div v-else key="alert">
        <button
          type="button"
          class="common-alert-btn"
          @click="ok"
          v-show="!isLoading"
        >
          {{ okText }}
        </button>
        <button
          type="button"
          class="common-alert-btn loading"
          v-show="isLoading"
        >
          <div class="nk-spinner"></div>
          <span class="pl5" v-if="LoadingText">{{ LoadingText }}</span>
        </button>
      </div>
    </div>
  </Modal>
</template>

<script>
import { defineComponent } from "vue";
import { Modal } from "vue-neat-modal";
import IonIcon from "@/components/IonIcon.vue";

export default defineComponent({
  name: "Alert",
  props: {
    isOpen: { type: Boolean, default: false },
    alignY: { type: String, default: "center" },
    okText: { type: String, default: "" },
    LoadingText: { type: String, default: "" },
    cancelText: { type: String, default: "" },
    text: { type: String, default: "" },
    clickOut: { type: Boolean, default: false },
    type: { type: String, default: `alert` },
    hasClose: { type: Boolean, default: true },
    useHTML: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
  },
  data() {
    return {};
  },
  mounted() {},
  //   emits: ['update:isOpen'],
  components: {
    Modal,
    IonIcon,
  },
  methods: {
    ok() {
      this.$emit("ok");
    },
    cancel() {
      this.$emit("cancel");
    },
    close() {
      this.$emit("update:isOpen", false);
    },
    afterLeave() {
      this.$emit("afterLeave");
    },
    closeDialog(e) {
      this.$emit("update:isOpen", e);
    },
  },
});
</script>

<style lang="less" scoped></style>
