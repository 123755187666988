import { createI18n } from 'vue-i18n/index'
import { getLang } from '@/utils/utils'
// 国际化
let lang = getLang()
const i18n = createI18n({
    locale: lang,
    // localStorage.getItem("lang") || lang, // set locale
    fallbackLocale: 'en',
    warnHtmlInMessage: 'off',
    messages: {
        ja: require('./ja.js'), // 日文语言包
        en: require('./en.js'), // 英语语言包
        zh: require('./zh.js'), // 中文语言包
        ko: require('./ko.js'), // 韩语语言包
    }
})

export default i18n
